<template>
  <div id="document-uploader">
    <div class="layout layout-fourth">
      <div
        v-for="document in documents"
        :key="document.id"
        class="picture-frame"
      >
        <div>
          <a
            v-if="document.category === 'picture'"
            class="picture"
            :href="document.src"
            target="_blank"
            :data-lightbox="document.id"
            :style="{ backgroundImage: `url(${document.thumbnail})` }"
          >
            <div class="filetype">
              <p>
                {{ document.type ? document.type.toUpperCase() : '' }}
              </p>
            </div>
          </a>
          <a
            v-else
            class="picture"
            :href="document.src"
            :target="document.category === 'pdf' ? '_blank' : '_self'"
            :style="documentBackground(document)"
          >
            <div class="filetype">
              <p>
                {{ document.type ? document.type.toUpperCase() : '' }}
              </p>
            </div>
          </a>
          <div
            v-if="canDelete"
            class="frame-control"
          >
            <a :href="document.delete">
              <img
                alt="Löschen"
                src="/images/block_inv.png"
              >
            </a>
          </div>
          <div
            class="caption"
            :title="document.name"
          >
            {{ document.name }}
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div
      v-if="!readonly"
      class="aTable-full transparent"
    >
      <div class="aTableCell">
        <select
          v-if="metadata !== null"
          v-model="kind"
        >
          <option value="">
            -
          </option>
          <option
            v-for="[key, entry] in metadata.entries()"
            :key="key"
            :value="key"
          >
            {{ entry }}
          </option>
        </select>
        <upload
          :server="server"
          @processfile="handleProcessFile"
          @addfile="handleAddFile"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Upload from '@/components/Document/UploadComponent'
import { request } from '@/js/request'

export function documentBackground (document) {
  switch (document.category) {
    case 'pdf':
      return { backgroundColor: '#A00000' }
    case 'office':
      return { backgroundColor: '#E83D00' }
    case 'archive':
      return { backgroundColor: '#6000A0' }
    default:
      return { backgroundColor: '#FFD000' }
  }
}

export function createBuildingMetadata () {
  const metadata = new Map()

  metadata.set('photo', 'Bild')
  metadata.set('apl', 'APL Doku')
  metadata.set('abnahmeprotokoll', 'Abnahmeprotokoll')
  metadata.set('konnektierung', 'Foto innen, außen, Konnektierung')
  return metadata
}

export default {
  components: {
    Upload
  },
  props: {
    server: {
      type: String,
      required: true
    },
    metadata: {
      type: Map,
      required: false,
      default: null
    },
    readonly: {
      type: Boolean,
      required: false,
      default: () => false
    },
    canDelete: {
      type: Boolean,
      required: false,
      default: () => false
    },
    deleteRoute: {
      type: String,
      required: false,
      default: null
    },
    routeParams: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      documents: [],
      kind: ''
    }
  },
  created () {
    this.create()
  },
  methods: {
    searchParams () {
      if (this.deleteRoute !== null && this.routeParams !== null) {
        const urlParams = new URLSearchParams(Object.assign({
          route: this.deleteRoute
        }, this.routeParams))
        return urlParams.toString()
      }
      return ''
    },
    create () {
      request('get', this.server,
        response => {
          this.documents = response.map(file => {
            return {
              ...file,
              delete: `/dokument/${file.id}/delete_file?${this.searchParams()}`,
              src: `/dokument/${file.id}/get`,
              thumbnail: `/dokument/${file.id}/thumbnail`
            }
          })
        }
      )
    },
    handleAddFile (error, file) {
      if (error || this.metadata === null) {
        return
      }
      file.setMetadata('kind', this.kind)
    },
    handleProcessFile (error, file) {
      if (error !== null) {
        throw error
      }
      this.create()
    },
    documentBackground
  }
}
</script>
